import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  actions,
  incentiveActions,
  incentiveUpdateCurrentPageCreator,
} from '../../redux';
import {
  inactiveNavbarDropdownDivisionCreator,
  incentiveNavActionHandlerCreator,
  incentivePageLists,
} from '../../redux/slice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  cutOffDateConfigurationCreator,
  cutOffDateConfigurationSelector,
  getCutOffDatesCreator,
  getYearlyQuarterCreator,
} from './redux';
import { API_PATH, TOAST_MESSAGES } from '@app/common/constants';
import { incentiveSelector } from '../../redux/selectors';
import { navbarComponentName } from '@app/router/redux/routeHandler';
import { routeStateActions } from '@app/router/redux';
import { CutOffDateConfigurationProps } from './types';
import {
  Divider,
  Grid,
  IconButton,
  Stack,
  TableRow,
  Typography,
} from '@mui/material';
import EditIcon from '@app/assets/svg/edit-icon';
import Popup from 'src/screens/directory-configuration/components/Popup';
import { Box } from '@mui/system';
import { NetworkService, ToasterService } from '@app/services';
import { ToastType } from '@app/services/toaster';
import IncentiveTableList from '../../components/Table';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import MultiSelectDropDown from '@app/components/multiSelectDropDown';
import { appSelector } from '@app/store/selectors';
import { CustomButton, SearchTextField, StyledAutocomplete, StyledCheckbox, StyledTableCell, StyleInputLabel } from '@app/screens/Styles/style';

const columns = ['division', 'quarter', 'date', 'Action'];

const initialCutOffDateConfigData = {
  id: 0,
  quarterId: 0,
  quarterName: '',
  date: new Date(),
  isActive: true,
  isEditable: false,
};

interface Quarter {
  label: string;
  value: number;
}

interface ResetData {
  divisionName: Quarter;
  quarter: Quarter;
  date: Date; // Assuming date is a string, adjust the type as needed
}

function CutOffDateConfiguration() {
  const dispatch = useDispatch();
  const [isUpdatingTab, setIsUpdatingTab] = useState(false);
  const [updatingError, setUpdatingError] = useState<null | string>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedQuarter, setSelectedQuarter] = useState<Quarter | null>(null);
  const [selectedDivision, setSelectedDivision] = useState<Quarter | null>(null);
  const [selectedDivisionNameListArr, setSelectedDivisionNameListArr] = useState([]);
  const [resetData, setResetData] = useState<ResetData | null>(null);
  const [isReset, setIsReset] = useState<number>(0);
  const [dateError, setDateError] = useState(false);
  const [updatedCutOffDateConfigData, setUpdatedCutOffDateConfigData] =
    useState(initialCutOffDateConfigData);
  const [addCutOffDateConfigData, setAddCutOffDateConfigData] = useState(
    updatedCutOffDateConfigData,
  );
  const isActive = useSelector(incentiveSelector.getStatus, shallowEqual);
  const cutOffDateConfigurationData = useSelector(
    cutOffDateConfigurationSelector.getCutOffDateConfigurationData(),
    shallowEqual,
  );
  const loading: any = useSelector(
    incentiveSelector.getLoading(),
    shallowEqual,
  );
  const search = useSelector(incentiveSelector.getNavSearch(), shallowEqual);
  const yearlyQuarter: any = useSelector(
    cutOffDateConfigurationSelector.getYearlyQuarter(),
    shallowEqual,
  );

  const divisionList = useSelector(incentiveSelector.getDivisionList(), shallowEqual);

  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const adminStaffPositionMasterId = userInfo.id;

  useEffect(() => {
    dispatch(
      inactiveNavbarDropdownDivisionCreator({
        adminStaffPositionMasterId: adminStaffPositionMasterId,
      }),
    );
    dispatch(getYearlyQuarterCreator({}));
    dispatch(getCutOffDatesCreator({}));
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.incentiveConfiguration,
      ),
    );
    dispatch(
      incentiveActions.updateNavData({
        title: 'Cut Off Date Configuration',
        status: { show: true, value: true },
        action: { title: 'Add New', type: actions.addCutOffDateConfiguration },
        search: { placeholder: 'Search Quarter/Division', query: '' },
      }),
    );
    dispatch(
      incentiveUpdateCurrentPageCreator(
        incentivePageLists.cutOffDateConfiguration,
      ),
    );

    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
    };
  }, []);

  useEffect(() => {
    /* Calling metric category list api */

    if (search?.query?.length >= 3 && isActive === 1) {
      dispatch(
        cutOffDateConfigurationCreator({
          pageIndex: 1,
          isActive: true,
          query: search?.query,
        }),
      );
    } else if (search?.query?.length >= 3 && isActive === 0) {
      dispatch(
        cutOffDateConfigurationCreator({
          pageIndex: 1,
          isActive: false,
          query: search?.query,
        }),
      );
    } else if (search?.query?.length === 0) {
      dispatch(
        cutOffDateConfigurationCreator({
          pageIndex: 1,
          isActive: isActive === 1,
          query: '',
        }),
      );
    }
  }, [isActive, search?.query]);

  useEffect(() => {
    setAddCutOffDateConfigData(updatedCutOffDateConfigData);
  }, [updatedCutOffDateConfigData]);

  const isRequestFetching = useMemo(
    () =>
      loading === API_PATH.cutOffDateConfiguration.fetchCutOffDateList &&
      (!cutOffDateConfigurationData ||
        cutOffDateConfigurationData.length === 0),
    [loading, cutOffDateConfigurationData],
  );

  const isUpdatingData = useMemo(
    () => loading === API_PATH.cutOffDateConfiguration.fetchCutOffDateList,
    [loading],
  );

  const isEmpty = useMemo(
    () =>
      !isRequestFetching &&
      (!cutOffDateConfigurationData ||
        cutOffDateConfigurationData.length === 0),
    [isRequestFetching, cutOffDateConfigurationData],
  );

  const onLoadMoreHandler = useCallback(
    (pageIndex: number) => {
      dispatch(
        cutOffDateConfigurationCreator({
          pageIndex,
          isActive: isActive === 1,
          query: search?.query || '',
        }),
      );
    },
    [search, isActive],
  );

  const modal = useSelector(
    incentiveSelector.getincentiveModal(),
    shallowEqual,
  );

  const calculateNextQuarterDateRange = (selectedQuarter: Quarter | null) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

    if (selectedQuarter !== null) {
      const match = RegExp(/Q(\d) FY(\d{2})/).exec(selectedQuarter.label);

      if (match) {
        const quarterNumber = parseInt(match[1], 10);
        const fiscalYear = parseInt(match[2], 10) + 1999; // Convert YY to YYYY
        // Check if the next quarter extends into the next calendar year
        const nextQuarterNumber = (quarterNumber % 4) + 1; // Increment by 1, and wrap around to 1 for Q4
        const startMonth = nextQuarterNumber * 3 + 1;
        const endMonth = startMonth + 2;

        if (quarterNumber === 4 && currentMonth >= 3) {
          // If Q4 and current month is April or later, increment fiscal year for Q1
          return {
            minDate: new Date(fiscalYear + 1, startMonth - 1, 1), // Minus 1 to convert to zero-indexed month
            maxDate: new Date(fiscalYear + 1, endMonth, 0), // Last day of the next quarter
          };
        } else {
          return {
            minDate: new Date(fiscalYear, startMonth - 1, 1), // Minus 1 to convert to zero-indexed month
            maxDate: new Date(fiscalYear, endMonth, 0), // Last day of the next quarter
          };
        }
      }
    }

    // Handle the case when selectedQuarter is null or doesn't match the expected format
    return {
      minDate: new Date(currentDate.getFullYear(), currentMonth, 1),
      maxDate: new Date(currentDate.getFullYear(), currentMonth + 2, 0), // Last day of the current quarter
    };
  };

  const { minDate: minDateFromQuarter, maxDate } = calculateNextQuarterDateRange(selectedQuarter);

  const minDate = moment(minDateFromQuarter).isBefore(moment()) ? new Date() : minDateFromQuarter;

  const [valueDate, setValueDate] = useState<Date | null>(minDate);

  const showAddModal = useMemo(
    () => modal?.show && modal?.type === actions.addCutOffDateConfiguration,
    [modal],
  );

  const cancelAddModal = useCallback(() => {
    setIsEditable(false);
    setSelectedQuarter(null);
    setValueDate(null);
    setSelectedDivision(null);
    setSelectedDivisionNameListArr([]);
    dispatch(incentiveNavActionHandlerCreator({ show: false, type: null }));
    setUpdatedCutOffDateConfigData(initialCutOffDateConfigData);
    setAddCutOffDateConfigData(updatedCutOffDateConfigData);
    setUpdatingError(null);
    setIsReset(1);
  }, []);

  const tabEditHandler = useCallback(async (id: number) => {
    setIsEditable(true);
    dispatch(
      incentiveNavActionHandlerCreator({
        show: true,
        type: actions.addCutOffDateConfiguration,
      }),
    );

    try {
      const response = await NetworkService.get(
        API_PATH.cutOffDateConfiguration.cutOffDateMasterById,
        { id },
        {},
      );
      if (response.status === 200) {
        const res = response.data;
        const data = {
          id: res.id,
          quarterId: res.quarterId,
          quarterName: res.quarterName,
          date: res.date,
          isActive: res.isActive,
          isEditable: res.isEditable,
          divisionName: res.divisionName
        };
        setSelectedQuarter({
          label: res.quarterName,
          value: res.quarterId,
        });
        setValueDate(res.date);
        setResetData({
          quarter: {
            label: res.quarterName,
            value: res.quarterId,
          },
          date: res.date,
          divisionName: {
            label: res.divisionName,
            value: res.divisionId
          }
        });
        setSelectedDivision({
          label: res.divisionName,
          value: res.divisionId
        })
        setUpdatedCutOffDateConfigData(data);
      } else {
        cancelAddModal();
        ToasterService.showToaster(
          'Error while fetching data',
          ToastType.ERROR,
        );
      }
    } catch (error: any) {
      cancelAddModal();
      ToasterService.showToaster('Error while fetching data', ToastType.ERROR);
    }
  }, []);

  const onAddUpdateHandler = useCallback(
    async (e: any) => {
      e.preventDefault();
      if(dateError) return;
      if (
        addCutOffDateConfigData.date &&
        selectedQuarter &&
        addCutOffDateConfigData.quarterId !== 0 &&
        addCutOffDateConfigData.quarterId.toString().trim()
      ) {
        setIsUpdatingTab(true);
        const updatedData = {
          id: addCutOffDateConfigData.id,
          quarterId: addCutOffDateConfigData.quarterId,
          date: addCutOffDateConfigData.date,
          isActive: addCutOffDateConfigData.isActive,
        };

        addCutOffDatesConfiguration(updatedData);
      } else {
        setUpdatingError('Please fill all required fields!!');
      }
    },
    [addCutOffDateConfigData, selectedDivision, selectedDivisionNameListArr, dateError],
  );

  function convertUtcToIstAndFormat(valueDate: Date | null): string | null {
    if (valueDate === null) {
      return null; // or handle the case where valueDate is null as needed
    }

    const istOffset = 5.5 * 60 * 60 * 1000; // IST offset is 5 hours and 30 minutes

    const utcDateTime = new Date(valueDate);
    const istDateTime = new Date(utcDateTime.getTime() + istOffset);

    // Format the date as a string in the specified format
    const formattedDate = istDateTime.toLocaleDateString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    return formattedDate;
  }

  const addCutOffDatesConfiguration = async (updatedData: any) => {
    try {
      let divisionId: any[] = []
      divisionList.forEach((val: any) => {
        selectedDivisionNameListArr.forEach((ele: string) => {
          if (val.label === ele) {
            divisionId.push(val.value);
          }
        })
      })

      const response = await NetworkService.post(
        API_PATH.cutOffDateConfiguration.saveCutOffDates,
        {
          id: updatedData.id,
          quarterId: updatedData.quarterId,
          date: convertUtcToIstAndFormat(updatedData.date),
          isActive: updatedData.isActive,
          divisionList: isEditable ? [selectedDivision?.value] : divisionId
        },
        {},
      );
      setIsUpdatingTab(false);
      if (response.status !== 200) {
        setUpdatingError(response.data.description);
      } else {
        ToasterService.showToaster(
          isEditable
            ? TOAST_MESSAGES.SUCCESSFULLY_UPDATED
            : TOAST_MESSAGES.SUCCESSFULLY_ADD,
          ToastType.SUCCESS,
        );
        dispatch(
          cutOffDateConfigurationCreator({
            pageIndex: 1,
            isActive: isActive === 1,
            query: search?.query,
          }),
        );
        cancelAddModal();
      }
    } catch (error: any) {
      setIsUpdatingTab(false);
      setUpdatingError(error.message);
    }
  };

  const handleReset = useCallback(() => {
    if (isEditable && resetData) {
      setValueDate(resetData?.date ?? null);
      setSelectedQuarter(resetData?.quarter ?? null);
      setSelectedDivision(resetData?.divisionName ?? null);
    } else {
      setValueDate(null);
      setSelectedQuarter(null);
      setSelectedDivision(null);
      setSelectedDivisionNameListArr([]);
      setIsReset(1);
    }
    setAddCutOffDateConfigData(updatedCutOffDateConfigData);
  }, [updatedCutOffDateConfigData, isReset, isEditable]);

  const onFormUpdateHandler = (data: any) => {
    if (updatingError) {
      setUpdatingError(null);
    }
    setAddCutOffDateConfigData({
      ...addCutOffDateConfigData,
      ...data,
    });
  };

  const onChangeQuarterHandler = useCallback(
    (event, val) => {
      setValueDate(null);
      setSelectedQuarter(val);
      onFormUpdateHandler({
        quarterId: val?.value,
        quarterName: val?.label,
      });
    },
    [onFormUpdateHandler],
  );
  const onChangeDivisionHandler = useCallback(
    (event, val) => {
      setSelectedDivision(val);
      onFormUpdateHandler({
        divisionId: val?.value,
        divisionName: val?.label,
      });
    },
    [onFormUpdateHandler, selectedDivision],
  );

  const onChangeIsActive = useCallback(
    event =>
      onFormUpdateHandler({
        isActive: event.target.checked ? 1 : 0,
      }),
    [onFormUpdateHandler],
  );

  const handleChangeStartdate = (newValue: Date | null) => {
    onFormUpdateHandler({ date: newValue });
    setValueDate(newValue);
  };

  useEffect(() => {
    if (!selectedQuarter) {
      setValueDate(null);
    }
    // else if (selectedQuarter) {
    // onFormUpdateHandler({date: minDate});
    // setValueDate(minDate);
    // }
  }, [valueDate]);

  useEffect(() => {
    if (selectedQuarter && !valueDate) {
      setValueDate(minDate);
      onFormUpdateHandler({ date: minDate });
    }
  }, [selectedQuarter]);

  return (
    <>
      <IncentiveTableList
        columns={columns}
        isEmpty={isEmpty}
        onLoadMore={onLoadMoreHandler}
        isPaging={true}
        isLoading={isUpdatingData}
      >
        {cutOffDateConfigurationData && cutOffDateConfigurationData.length > 0
          ? cutOffDateConfigurationData?.map(
            (item: CutOffDateConfigurationProps, index: number) => (
              <>
                <TableRow key={item.id}>
                  <StyledTableCell>
                    {item.divisionName ? item.divisionName : '-'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.quarterName ? item.quarterName : '-'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.date
                      ? moment(item.date).format('DD-MMM-YYYY')
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {item.isEditable && (
                      <IconButton
                        sx={{ p: 0 }}
                        data-current={JSON.stringify(item)}
                        onClick={() => {
                          tabEditHandler(item.id);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                </TableRow>
                <Divider
                  component={TableRow}
                  variant="fullWidth"
                  sx={{ height: 5, border: 0 }}
                />
              </>
            ),
          )
          : null}
      </IncentiveTableList>
      <Popup
        open={!!showAddModal}
        cancelPopup={cancelAddModal}
        isLoading={isUpdatingTab}
        onClose={cancelAddModal}
        error={updatingError}
        title={isEditable ? 'Edit Cut Off Date' : 'Add Cut Off Date'}
      >
        <form
          onSubmit={onAddUpdateHandler}
          style={{ maxHeight: 500, overflow: 'auto' }}
        >

          {isEditable ? <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Select Division
            </StyleInputLabel>
            <StyledAutocomplete
              id="division-select"
              options={divisionList}
              value={selectedDivision}
              onChange={onChangeDivisionHandler}
              renderInput={params => <SearchTextField {...params} />}
            />
          </Box> :

            <Box sx={{ mb: 3 }}>
              <StyleInputLabel variant="standard" required>
                Select Division
              </StyleInputLabel>
              <MultiSelectDropDown
                width={480}
                selectedValues={(e: any) => {
                  setSelectedDivisionNameListArr(e)
                  setIsReset(0)
                }}
                placeholder="Select Division"
                isSelected={selectedDivision}
                options={divisionList}
                reset={isReset}
              />
            </Box>
          }
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Select Quarter
            </StyleInputLabel>
            <StyledAutocomplete
              id="quarter-select"
              options={yearlyQuarter}
              value={selectedQuarter}
              onChange={onChangeQuarterHandler}
              renderInput={params => <SearchTextField {...params} />}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Select Date
            </StyleInputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DatePicker
                  views={['day']}
                  mask="__/__/____"
                  inputFormat="dd/MM/yyyy"
                  value={valueDate}
                  onChange={handleChangeStartdate}
                  onError={(error) => {
                    setDateError(!!error);
                    console.log("onError", !!error);
                  }}
                  renderInput={params => {
                    return (
                      <SearchTextField placeholder="Select" required {...params} />
                    )
                  }}
                  disabled={!selectedQuarter}
                  minDate={minDate}
                  maxDate={maxDate}
                  openTo="day"
                />
                {dateError ? <Typography style={{marginTop: 5}} sx={{color: 'red', mt: 0, fontSize: 12, fontWeight: 400}}>Please select correct date</Typography> : null}
              </Stack>
            </LocalizationProvider>
          </Box>
          <Box>
          <Box sx={{ my: 3 }}>
            <StyleInputLabel variant="standard">Is active</StyleInputLabel>
          </Box>

          <Box sx={{ mb: 3 }}>
            <StyledCheckbox
              checked={addCutOffDateConfigData.isActive}
              onChange={onChangeIsActive}
            />
          </Box>
          </Box>
          <Grid
            container
            justifyContent={'center'}
            sx={{ mb: 2 }}
            alignItems={'center'}
            position={'-webkit-sticky'}
          >
            <Grid item mr={2}>
              <CustomButton variant="outlined" onClick={handleReset}>
                Reset
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton variant="contained" type="submit">
                Save
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </Popup>
    </>
  );
}

export default CutOffDateConfiguration;
