import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {routeStateActions} from '@app/router/redux';
import Popup from 'src/screens/directory-configuration/components/Popup';
import {appSelector} from '@app/store/selectors';
import {
  actions,
  incentiveActions,
  incentiveNavActionHandlerCreator,
  incentiveUpdateCurrentPageCreator,
} from '../../redux';
import {
  inactiveNavbarDropdownCreator,
  inactiveNavbarDropdownDivisionCreator,
  incentivePageLists,
} from '../../redux/slice';
import {
  salesTransferConfigurationCreator,
  salesTransferConfigurationSelector,
  getSalesTransferConfigurationQuarterCreator,
  getSalesTransferConfigurationConCreator,
} from './redux';
import {
  Box,
  TableRow,
  Divider,
  IconButton,
  Grid,
} from '@mui/material';
import EditIcon from '@app/assets/svg/edit-icon';
import {API_PATH, TOAST_MESSAGES} from '@app/common/constants';
import {incentiveSelector} from '../../redux/selectors';
import {
  getSalesTransferConfigurationDivisionCreator,
  getSalesTransferConfigurationStateCreator,
} from './redux/slice';
import FilterHeader from './filter';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import IncentiveTableList from '../../components/Table';
import { CustomButton, SearchTextField, StyledAutocomplete, StyledCheckbox, StyledTableCell, StyleInputLabel } from '@app/screens/Styles/style';
import {ConfirmationDialog} from '@app/components/widgets/ConfirmationDialog';

const columns = [
  'DIVISION',
  // 'STATE',
  'QUARTER',
  'CONFIGURATION',
  'ACTION',
];

const initialSalesTransferConfigData = {
  id: 0,
  isActive: true,
  divisionId: 0,
  division: '',
  stateId: [],
  state: '',
  quarterId: 0,
  quarter: '',
  configurationId: 0,
  configuration: '',
};

const SalesTranferConfiguration = () => {
  const dispatch = useDispatch();
  const search = useSelector(incentiveSelector.getNavSearch(), shallowEqual);
  const isActive = useSelector(incentiveSelector.getStatus, shallowEqual);
  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const adminStaffPositionMasterId = userInfo.id;
  const [isEditable, setIsEditable] = useState(false);
  const [updatedSalesTranConfigData, setUpdatedSalesTranConfigData] = useState(
    initialSalesTransferConfigData,
  );

  const [addSalesTranConfigData, setAddSalesTranConfigData] = useState(
    updatedSalesTranConfigData,
  );
  const [updatingError, setUpdatingError] = useState<null | string>(null);
  const [isUpdatingTab, setIsUpdatingTab] = useState(false);
  const [selectedFilterData, setSelectedFilterData] = useState<any>();
  const [showConfigChangeConfirmation, setShowConfigChangeConfirmation] =
    useState<boolean>(false);

  const divisionList = useSelector(incentiveSelector.getDivisionList());

  const configurationList = useSelector(
    salesTransferConfigurationSelector.getSalesTransferConfigurationConData(),
  );

  const quarterList = useSelector(
    salesTransferConfigurationSelector.getSalesTransferConfigurationQuarterData(),
  );

  const loading: any = useSelector(
    incentiveSelector.getLoading(),
    shallowEqual,
  );

  const salesTransferConfigurationtData = useSelector(
    salesTransferConfigurationSelector.getSalesTransferConfigurationData(),
    shallowEqual,
  );

  const isTabsFetching: boolean = useMemo(
    () =>
      loading ===
        API_PATH.incentiveConfiguration.fetchSalesTransferConfiguration &&
      (!salesTransferConfigurationtData ||
        salesTransferConfigurationtData.length === 0),
    [loading, salesTransferConfigurationtData],
  );

  const isUpdatingData = useMemo(
    () =>
      loading ===
      API_PATH.incentiveConfiguration.fetchSalesTransferConfiguration,
    [loading],
  );

  const isEmpty = useMemo(
    () =>
      !isTabsFetching &&
      (!salesTransferConfigurationtData ||
        salesTransferConfigurationtData.length === 0),
    [isTabsFetching, salesTransferConfigurationtData],
  );

  // For Adding popup
  const modal = useSelector(
    incentiveSelector.getincentiveModal(),
    shallowEqual,
  );

  const showAddModal = useMemo(
    () => modal?.show && modal?.type === actions.addSalesTransferConfiguration,
    [modal],
  );

  const cancelAddModal = useCallback(() => {
    setIsEditable(false);
    setUpdatedSalesTranConfigData(initialSalesTransferConfigData);
    setAddSalesTranConfigData(updatedSalesTranConfigData);
    dispatch(incentiveNavActionHandlerCreator({show: false, type: null}));
    setUpdatingError(null);
  }, []);

  const onFormUpdateHandler = (data: any) => {
    if (updatingError) {
      setUpdatingError(null);
    }

    setAddSalesTranConfigData({
      ...addSalesTranConfigData,
      ...data,
    });
  };

  const onAddUpdateHandler = useCallback(
    async (e: any) => {
      e.preventDefault();
      if (
        addSalesTranConfigData.divisionId.toString().trim() &&
        addSalesTranConfigData.quarterId.toString().trim() &&
        addSalesTranConfigData.configuration.toString().trim()
      ) {
        if (
          isEditable &&
          addSalesTranConfigData.configurationId !=
            updatedSalesTranConfigData.configurationId
        ) {
          setShowConfigChangeConfirmation(true);
        } else {
          handleSubmitData();
        }
      } else {
        setUpdatingError('Please fill all required fields!!');
      }
    },
    [addSalesTranConfigData],
  );

  const handleSubmitData = () => {
    setIsUpdatingTab(true);
    let updatedData = {};
    if (isEditable) {
      updatedData = {
        id: addSalesTranConfigData.id,
        divisionId: addSalesTranConfigData.divisionId,
        configurationId: addSalesTranConfigData.configurationId,
        quarterId: addSalesTranConfigData.quarterId,
        stateId: addSalesTranConfigData.stateId,
        isActive: addSalesTranConfigData.isActive,
      };
    } else {
      updatedData = {
        id: addSalesTranConfigData.id,
        divisionId: addSalesTranConfigData.divisionId,
        configurationId: addSalesTranConfigData.configurationId,
        quarterId: addSalesTranConfigData.quarterId,
        stateIdList: addSalesTranConfigData.stateId,
        isActive: addSalesTranConfigData.isActive,
      };
    }
    addCutOffDatesConfiguration(updatedData);
  };

  const addCutOffDatesConfiguration = async (updatedData: any) => {
    try {
      const response = await NetworkService.post(
        API_PATH.incentiveConfiguration.addUpdateSalestransferConfiguration,
        updatedData,
        {},
      );
      setIsUpdatingTab(false);

      if (response.status !== 200) {
        setUpdatingError(response.data.description);
      } else {
        ToasterService.showToaster(
          isEditable
            ? TOAST_MESSAGES.SUCCESSFULLY_UPDATED
            : TOAST_MESSAGES.SUCCESSFULLY_ADD,
          ToastType.SUCCESS,
        );
        dispatch(
          salesTransferConfigurationCreator({
            pageIndex: 1,
            isActive: isActive === 1,
            pageSize: 15,
            quarterId: 0,
            quarter: search?.query || '',
            configurationId: 0,
            divisionIdList: [],
            stateIdList: [],
          }),
        );
        cancelAddModal();
      }
    } catch (error: any) {
      setIsUpdatingTab(false);
      setUpdatingError(error.message);
    }
  };

  useEffect(() => {
    setAddSalesTranConfigData(updatedSalesTranConfigData);
  }, [updatedSalesTranConfigData]);

  const handleReset = useCallback(() => {
    setAddSalesTranConfigData(updatedSalesTranConfigData);
  }, [updatedSalesTranConfigData]);

  const salesTransferConfigEditHandler = (currentData: any) => {
    setAddSalesTranConfigData({
      ...initialSalesTransferConfigData,
      ...currentData,
    });
    dispatch(
      incentiveNavActionHandlerCreator({
        show: true,
        type: actions.addSalesTransferConfiguration,
      }),
    );
  };

  const onClickHandldeEdit = useCallback(async event => {
    setIsEditable(true);
    const item = JSON.parse(event.currentTarget.dataset.current);
    const response = await NetworkService.get(
      API_PATH.incentiveConfiguration.editSalestransferConfiguration,
      {id: item.id},
      {},
    );
    salesTransferConfigEditHandler(response.data);
    setUpdatedSalesTranConfigData(response.data);
    setUpdatingError(null);
  }, []);

  //

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.incentiveConfiguration,
      ),
    );
    dispatch(inactiveNavbarDropdownCreator('a'));
    dispatch(
      inactiveNavbarDropdownDivisionCreator({
        adminStaffPositionMasterId: adminStaffPositionMasterId,
      }),
    );
    dispatch(getSalesTransferConfigurationQuarterCreator('a'));
    dispatch(getSalesTransferConfigurationConCreator('a'));
    dispatch(
      incentiveActions.updateNavData({
        title: 'Sales Transfer Configuration',
        status: {show: true, value: true},
        search: {placeholder: 'Search Quarter', query: ''},
        action: {
          title: 'Add New',
          type: actions.addSalesTransferConfiguration,
        },
      }),
    );

    dispatch(
      incentiveUpdateCurrentPageCreator(
        incentivePageLists.salesTransferConfiguration,
      ),
    );

    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(incentiveActions.clearState());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickOnGoBtn = (params: any) => {
    setSelectedFilterData(params);
  };

  const onLoadMoreHandler = useCallback(
    (index: number) => {
      const requestParams: any = {
        pageIndex: index,
        isActive: isActive === 1,
        pageSize: 15,
        quarter: search?.query || '',
        divisionIdList: selectedFilterData?.divisionIdList
          ? selectedFilterData?.divisionIdList
          : [],
        quarterId: selectedFilterData?.quarterId
          ? selectedFilterData?.quarterId
          : 0,
        configurationId: selectedFilterData?.configurationId
          ? selectedFilterData?.configurationId
          : 0,
      };
      dispatch(salesTransferConfigurationCreator(requestParams));
    },
    [search, isActive, selectedFilterData],
  );

  useEffect(() => {
    const requestParams: any = {
      pageIndex: 1,
      isActive: isActive === 1,
      pageSize: 15,
      quarterId: selectedFilterData?.quarterId
          ? selectedFilterData?.quarterId
          : 0,
      quarter: search?.query,
      configurationId: selectedFilterData?.configurationId
          ? selectedFilterData?.configurationId
          : 0,
          divisionIdList: selectedFilterData?.divisionIdList
          ? selectedFilterData?.divisionIdList
          : [],
      stateIdList: [],
    };

    if (search?.query?.length >= 3 && isActive === 1) {
      dispatch(salesTransferConfigurationCreator(requestParams));
    } else if (search?.query?.length >= 3 && isActive === 0) {
      dispatch(salesTransferConfigurationCreator(requestParams));
    } else if (
      search?.query?.length === 0 &&
      (isActive === 1 || isActive === 0)
    ) {
      dispatch(salesTransferConfigurationCreator(requestParams));

      dispatch(getSalesTransferConfigurationDivisionCreator({}));
      dispatch(getSalesTransferConfigurationStateCreator({}));
    }
  }, [isActive, search?.query]);

  const onChangeIsActive = useCallback(
    event =>
      onFormUpdateHandler({
        isActive: event.target.checked,
      }),
    [onFormUpdateHandler],
  );

  const onChangeDivisionHandle = useCallback(
    (_, val: any) => {
      onFormUpdateHandler({
        division: val?.label,
        divisionId: val?.value,
      });
    },
    [onFormUpdateHandler],
  );

  const onChangeQuarterHandle = useCallback(
    (_, val: any) => {
      onFormUpdateHandler({
        quarter: val?.label,
        quarterId: val?.value,
      });
    },
    [onFormUpdateHandler],
  );

  const onChnageConfigurationhandle = useCallback(
    (_, val: any) => {
      onFormUpdateHandler({
        configuration: val?.label,
        configurationId: val?.value,
      });
    },
    [onFormUpdateHandler],
  );

  const renderCategoryDropdownInput = useCallback(
    (params: any) => (
      <SearchTextField
        {...params}
        required={params.length === 0}
        placeholder="Select"
      />
    ),
    [],
  );

  return (
    <>
      <FilterHeader clickOnGoBtn={clickOnGoBtn} />
      <IncentiveTableList
        maxHeight={330}
        columns={columns}
        isEmpty={isEmpty}
        onLoadMore={onLoadMoreHandler}
        isPaging={true}
        isLoading={isUpdatingData}
      >
        {salesTransferConfigurationtData &&
        salesTransferConfigurationtData.length > 0
          ? salesTransferConfigurationtData?.map((item: any, index: number) => (
              <Fragment key={item?.id?.toString()}>
                <TableRow>
                  <StyledTableCell>{item?.division || '-'}</StyledTableCell>
                  <StyledTableCell>{item?.quarter || '-'}</StyledTableCell>
                  <StyledTableCell>{item?.configuration || '-'}</StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton
                      sx={{p: 0}}
                      data-current={JSON.stringify(item)}
                      onClick={onClickHandldeEdit}
                    >
                      <EditIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
                <Divider
                  component={TableRow}
                  variant="fullWidth"
                  sx={{height: 5, border: 0}}
                />
              </Fragment>
            ))
          : null}
      </IncentiveTableList>

      <Popup
        open={!!showAddModal}
        cancelPopup={cancelAddModal}
        isLoading={isUpdatingTab}
        onClose={cancelAddModal}
        error={updatingError}
        title={
          isEditable
            ? 'Edit Sales Transfer Configuration'
            : 'Add Sales Transfer Configuration'
        }
      >
        <form
          onSubmit={onAddUpdateHandler}
          style={{maxHeight: 500, overflow: 'auto'}}
        >
          <Box sx={{mb: 3}}>
            <StyleInputLabel variant="standard" required>
              Select Division
            </StyleInputLabel>
            <StyledAutocomplete
              id="country-select-demo"
              sx={{width: 450}}
              options={divisionList || []}
              disabled={!divisionList || divisionList?.length === 0}
              renderInput={renderCategoryDropdownInput}
              onChange={onChangeDivisionHandle}
              value={addSalesTranConfigData.division}
            />
          </Box>
          <Box sx={{mb: 3}}>
            <StyleInputLabel variant="standard" required>
              Select Quarter
            </StyleInputLabel>
            <StyledAutocomplete
              id="country-select-demo"
              sx={{width: 450}}
              options={quarterList || []}
              disabled={!quarterList || quarterList?.length === 0}
              renderInput={renderCategoryDropdownInput}
              onChange={onChangeQuarterHandle}
              value={addSalesTranConfigData?.quarter}
            />
          </Box>

          <Box sx={{mb: 3}}>
            <StyleInputLabel variant="standard" required>
              Configuration
            </StyleInputLabel>
            <StyledAutocomplete
              id="country-select-demo"
              sx={{width: 450}}
              options={configurationList || []}
              disabled={!configurationList || configurationList?.length === 0}
              renderInput={renderCategoryDropdownInput}
              onChange={onChnageConfigurationhandle}
              value={addSalesTranConfigData.configuration}
            />
          </Box>
          <Box sx={{my: 3}}>
            <StyleInputLabel variant="standard">Is active</StyleInputLabel>
          </Box>

          <Box sx={{mb: 3}}>
            <StyledCheckbox
              checked={addSalesTranConfigData.isActive}
              onChange={onChangeIsActive}
            />
          </Box>
          <Grid
            container
            justifyContent={'center'}
            sx={{
              mb: 2,
              position: 'sticky',
              bottom: 20,
              backgroundColor: '#ffffff',
              zIndex: 1000,
              paddingTop: 2,
            }}
            alignItems={'center'}
            position={'-webkit-sticky'}
          >
            <Grid item mr={2}>
              <CustomButton variant="outlined" onClick={handleReset}>
                Reset
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton variant="contained" type="submit">
                Save
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </Popup>
      <ConfirmationDialog
        open={showConfigChangeConfirmation}
        onClose={(status: boolean) => {
          setShowConfigChangeConfirmation(false);
          if (status) handleSubmitData();
        }}
        yesText="Proceed"
        noText="Cancel"
        message={`For selected division, ${addSalesTranConfigData.quarter} saved date at ${updatedSalesTranConfigData.configuration} will be deleted. are you still want to proceed?`}
      />
    </>
  );
};

export default SalesTranferConfiguration;
